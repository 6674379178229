import React, { useState, useEffect } from 'react';
import './LoginSignupModal.css';
import Logo from './Logo';
import LoginIcon from '@mui/icons-material/Login';
import OtpComponent from './OtpComponent';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  signup,
  customerLogin,
  getCities,
  forgotPassword,
  newPasswordCustomer,
} from '../APIS';
function LoginSignupModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSignupActive, setIsSignupActive] = useState(false);
  const [username, setUsername] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [city, setCity] = useState();
  const [cities, setCities] = useState([]);
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isOtpActive, setIsOtpActive] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [token, setToken] = useState('');

  const navigate = useNavigate();

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setIsOtpActive(false);
  };

  const toggleSignup = () => setIsSignupActive(!isSignupActive);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  const changePassword = async (newPassword, confirmNewPassword) => {
    if (newPassword === confirmNewPassword) {
      try {
        const response = await newPasswordCustomer(newPassword, token);
        if (response.status === 'success') {
          toast.success(response.msg);
          closeModal();
          navigate('/');
        }
      } catch (error) {
        throw error;
      }
    } else {
      toast.error('الرجاء التأكد من التطابق');
    }
  };

  const handleForgetPasswordButton = async (mobile) => {
    if (mobile) {
      try {
        const response = await forgotPassword(mobile);

        console.log(response);
      } catch (error) {
        throw error;
      }
      // Add your logic here
      console.log('Forget Password button clicked');
      setIsOtpActive(true);
    } else {
      toast.error('الرجاء ادخال رقم الهاتف');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(null); // Clear any previous errors

    // Simulate login/signup validation (replace with your backend logic)
    if (isSignupActive) {
      // Signup validation (check password match etc.)
      if (password !== confirmPassword) {
        setErrorMessage('كلمة المرور لا تطابق التأكيد');
        return;
      }

      if (newUsername && address && city && mobile && password) {
        try {
          const response = await signup({
            name: newUsername,
            mobile,
            city,
            address,
            password,
          });

          if (response.status == 'success') {
            localStorage.setItem('token', response.token);
            window.location.reload();
          } else {
            setErrorMessage(response.msg);
          }
        } catch (error) {
          throw error;
        }
      } else {
        setErrorMessage('جميع البيانات مطلوبة');
      }
    } else {
      // Login validation
      if (mobile && password) {
        try {
          const response = await customerLogin({ mobile, password });

          if (response.status === 'success') {
            localStorage.setItem('token', response.token);
            window.location.reload();
          } else {
            setErrorMessage('اسم المستخدم او كلمة المرور غير صحيحة');
          }
        } catch (error) {
          throw error;
        }
      } else {
        setErrorMessage('اسم المستخدم او كلمة المرور غير صحيحة');
      }
    }
  };

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await getCities();

        if (response.status === 'success') {
          setCities(response.data);
        }
      } catch (error) {
        throw error;
      }
    };

    fetchCities();
  }, []);

  return (
    <>
      <div
        onClick={openModal}
        style={{
          height: 52,
          display: 'flex',
        }}
      >
        <LoginIcon
          style={{
            width: '40px',
            height: '40px',
            marginRight: '10px',
            marginTop: 'auto',
            marginBottom: 'auto',
            cursor: 'pointer',
            color: '#fcc318',
          }}
        />
      </div>

      {isOpen && (
        <div className='modal-overlay'>
          <div className='modal-content'>
            <button className='close-button' onClick={closeModal}>
              X
            </button>
            <Logo />
            {isOtpActive ? (
              openChangePassword ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type='password'
                    id='password'
                    name='password'
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    className='styled-input'
                    placeholder='كلمة المرور'
                    style={{
                      fontWeight: 'bold',
                    }}
                  />
                  <input
                    type='password'
                    id='password'
                    name='password'
                    value={confirmNewPassword}
                    onChange={(e) => {
                      setConfirmNewPassword(e.target.value);
                    }}
                    className='styled-input'
                    placeholder='تأكيد كلمة المرور'
                    style={{
                      fontWeight: 'bold',
                    }}
                  />

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#fcc318',
                      borderRadius: '5px',
                      width: '180px',
                      height: '50px',
                      marginTop: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      changePassword(newPassword, confirmNewPassword);
                    }}
                  >
                    <p style={{ fontWeight: 'bold' }}>تأكيد</p>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <p>الرجاء ادخال الرمز الذي تم ارساله الى الرقم المدخل</p>
                  <OtpComponent
                    mobile={mobile}
                    setOpenChangePassword={setOpenChangePassword}
                    setToken={setToken}
                  />
                </div>
              )
            ) : (
              <>
                <form onSubmit={handleSubmit}>
                  {isSignupActive && (
                    <>
                      <input
                        type='text'
                        id='new_username'
                        name='new_username'
                        value={newUsername}
                        onChange={(e) => setNewUsername(e.target.value)}
                        className='styled-input'
                        placeholder='اسم المستخدم'
                        style={{
                          fontWeight: 'bold',
                        }}
                      />
                    </>
                  )}

                  {isSignupActive && (
                    <>
                      <input
                        type='text'
                        id='address'
                        name='address'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className='styled-input'
                        placeholder='العنوان'
                        style={{
                          fontWeight: 'bold',
                        }}
                      />
                    </>
                  )}

                  {isSignupActive && (
                    <>
                      <select
                        id='city'
                        name='city'
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className='styled-select'
                        style={{
                          borderRadius: '5px',
                          border: 'none',
                          flex: 1,
                          textAlign: 'right',
                          fontWeight: 'bold',
                          padding: '10px',
                          outline: 'none',
                          border: '1px solid #ccc',
                          backgroundColor: '#fff',
                          color: '#000',
                          direction: 'rtl',
                          width: '100%',
                          height: '49px',
                        }}
                      >
                        <option value='' disabled>
                          اختر المدينة
                        </option>
                        {cities.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  <input
                    type='text'
                    id='mobile'
                    name='mobile'
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    className='styled-input'
                    placeholder='رقم الهاتف'
                    style={{
                      fontWeight: 'bold',
                    }}
                  />

                  <input
                    type='password'
                    id='password'
                    name='password'
                    value={password}
                    onChange={handleChange}
                    className='styled-input'
                    placeholder='كلمة المرور'
                    style={{
                      fontWeight: 'bold',
                    }}
                  />

                  {!isSignupActive && (
                    <div
                      style={{
                        cursor: 'pointer',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                      onClick={() => {
                        handleForgetPasswordButton(mobile);
                      }}
                    >
                      <p
                        style={{
                          color: '#007bff',
                          textAlign: 'right',
                          marginTop: '-10px',
                          marginBottom: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        هل نسيت كلمة المرور ؟
                      </p>
                    </div>
                  )}
                  {isSignupActive && (
                    <>
                      <input
                        type='password'
                        id='confirmPassword'
                        name='confirmPassword'
                        value={confirmPassword}
                        onChange={handleChange}
                        className='styled-input'
                        placeholder='تاكيد كلمة المرور'
                        style={{
                          fontWeight: 'bold',
                        }}
                      />
                    </>
                  )}
                  {errorMessage && (
                    <p className='error-message'>{errorMessage}</p>
                  )}
                  <button className='styled-button' type='submit'>
                    {isSignupActive ? 'انشاء حساب' : 'تسجيل الدخول'}
                  </button>
                  <p className='toggle-text' onClick={toggleSignup}>
                    {isSignupActive
                      ? 'لديك حساب بالفعل؟ تسجيل الدخول'
                      : 'ليس لديك حساب؟ أنشئ حساب'}
                  </p>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default LoginSignupModal;
