import React from 'react';
import logo from '../assets/images/logo.png';
import { isMobile } from 'react-device-detect';
import { isTablet } from '../deviceUtils';
import { useNavigate } from 'react-router-dom';

const Logo = (props) => {
  const navigate = useNavigate();
  return (
    <div style={props.style}>
      <img
        onClick={() => {
          navigate('/');
        }}
        src={logo}
        style={{
          width: isMobile ? '60px' : '90px',
          height: isMobile ? '60px' : '90px',
          marginLeft: isMobile && 10,
          cursor: 'pointer',
        }}
        alt='logo'
      />
    </div>
  );
};

export default Logo;
