import React, { useState } from 'react';
import './BreadCrumb.css';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { isTablet } from '../../deviceUtils';

import useLocalStorageListener from '../LocalStorageListener';
const Breadcrumb = ({ items }) => {
  const [storageData, setStorageData] = useState(
    `[{"label":"الرئيسية","path":"/"}]`
  );

  // Callback function to handle storage changes
  const handleStorageChange = (event) => {
    // You can add conditions here to check specific keys
    setStorageData(localStorage.getItem('breadCrump'));
  };

  // Use the custom hook
  useLocalStorageListener(handleStorageChange);
  const isTablet = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    // Define tablet dimensions (you can adjust these as needed)
    const isTabletSize = width >= 768 && width <= 1024 && height <= 1366;
    return isTabletSize;
  };
  return (
    <nav aria-label='Breadcrumb'>
      <ul
        className={isMobile || isTablet() ? 'breadcrumbmobile' : 'breadcrumb'}
      >
        {JSON.parse(storageData).map((item, index) => (
          <Link
            to={item.path}
            key={index}
            style={{
              color: index == items.length - 1 ? '#00000099' : '#000',
              textDecoration: 'none',
            }}
          >
            {index > 0 && <span> / </span>}
            {item.label}
          </Link>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
