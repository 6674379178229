import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { notfound } from '../APIS';
const ProductCard = (props) => {
  return (
    <>
      {props.product ? (
        <div
          style={{
            overflow: 'hidden',
            borderRadius: 10,
            marginTop: 10,
            cursor: 'pointer',
            transition: 'transform 0.3s ease-in-out',
            transform: 'scale(1)',
            border: 'solid 1px #00000011',

            borderRadius: 10,
            backgroundColor: '#fff',
            borderColor: '#00000011',
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.transform = 'scale(1.05)')
          }
          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
        >
          <div
            style={{
              padding: 0,
              position: 'relative',
            }}
          >
            <Link
              to={`/product/${props.product.id}`}
              style={{ textDecoration: 'none', color: 'Black' }}
            >
              <div
                style={{
                  position: 'absolute',
                  paddingLeft: 10,
                  paddingRight: 10,
                  backgroundColor: '#fcc318',
                  borderBottomLeftRadius: 10,
                  borderTopRightRadius: 10,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {props.product.price} ₪
                </p>
                {props.product.deletedprice != 0 && (
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: 12,
                      marginTop: 'auto',
                      marginRight: 5,
                    }}
                    className={'diagonal-strikethrough'}
                  >
                    {props.product.deletedprice} ₪
                  </span>
                )}
              </div>
              <img
                src={props.product.image || notfound}
                alt={props.product.name}
                style={{
                  width: '100%',
                  aspectRatio: 1 / 1,
                  height: 'auto',
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  border: 'solid 1px',
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderLeftWidth: 0,
                  borderBottomWidth: 1,
                  borderColor: '#00000011',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: 12,
                    textAlign: 'right',
                    color: '#00000066',
                    marginRight: 10,
                  }}
                >
                  {props.product.categoryName}
                </p>
              </div>

              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 12,
                  textAlign: 'right',
                  marginBottom: 10,
                  marginRight: 5,
                  marginLeft: 5,
                }}
              >
                {props.product.name}
              </p>
            </Link>
          </div>
        </div>
      ) : (
        <Skeleton variant='rectangular' />
      )}
    </>
  );
};

export default ProductCard;
