import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { Navigate } from 'react-router-dom';
import { checkOTP } from '../APIS';
import { toast } from 'react-toastify';

const OtpComponent = ({ mobile, setOpenChangePassword, setToken }) => {
  const [otp, setOtp] = useState(''); // Initialize state for OTP
  const [verify, setVerify] = useState(false);

  const check = async (mobile, otp) => {
    try {
      const response = await checkOTP(mobile, otp);
      if (response.status === 'success') {
        setToken(response.token);
        setOpenChangePassword(true);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        direction: 'ltr',
      }}
    >
      <div style={{ margin: '10px' }}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          isInputNum={true}
          renderSeparator={<span>-</span>}
          renderInput={(props) => (
            <input
              {...props}
              style={{
                width: '2rem',
                height: '2rem',
                textAlign: 'center',
                marginLeft: 5,
                marginRight: 5,
              }}
            />
          )}
          inputStyle={{
            direction: 'rtl',
            width: '2rem',
            height: '2rem',
            margin: '0 0.5rem',
            fontSize: '1rem',
            borderRadius: '4px',
            border: '1px solid #ccc',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fcc318',
          borderRadius: '5px',
          width: '180px',
          height: '50px',
          marginTop: '10px',
          cursor: 'pointer',
        }}
        onClick={() => {
          check(mobile, otp);
        }}
      >
        <p
          style={{
            marginTop: 'auto',
            marginBottom: 'auto',
            fontWeight: 'bold',
          }}
        >
          ارسال
        </p>
      </div>
    </div>
  );
};

export default OtpComponent;
