import React from 'react';
import './SectionHeader.css'; // Import the CSS file

const SectionHeader = ({ title }) => {
  return (
    <div className='section-header'>
      <div className='section-content'>
        <p className='section-title'>{title}</p>
      </div>
    </div>
  );
};

export default SectionHeader;
