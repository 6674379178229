import React from 'react';
import { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const PlusMinusButton = ({ value, minValue, maxValue, onChange }) => {
  const handleIncrement = () => {
    if (value < maxValue) {
      onChange(value + 1);
    }
  };

  const handleDecrement = () => {
    if (value > minValue) {
      onChange(value - 1);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #CED4DA',
        width: 'fit-content',
        borderRadius: '5px',
        overflow: 'hidden',
      }}
    >
      <div
        aria-label='decrement'
        onClick={handleDecrement}
        disabled={value <= minValue}
        style={{
          marginRight: '0',
          borderRight: '1px solid #CED4DA',
          padding: '5px',
          marginTop: 'auto',
          marginBottom: 'auto',
          display: 'grid',

          cursor: value == 0 ? 'not-allowed' : 'pointer',
        }}
      >
        <RemoveIcon
          sx={{
            marginTop: 'auto',
            marginBottom: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            color: '#00000077',
          }}
        />
      </div>
      <Typography
        variant='body1'
        style={{
          padding: '5px',
          minWidth: '35px',
          textAlign: 'center',
          margin: '0',
          fontWeight: 'bold',
        }}
      >
        {value}
      </Typography>
      <div
        aria-label='increment'
        onClick={handleIncrement}
        disabled={value >= maxValue}
        style={{
          marginLeft: '0',
          borderLeft: '1px solid #CED4DA',
          marginTop: 'auto',
          marginBottom: 'auto',
          padding: '5px',
          display: 'grid',

          cursor: 'pointer',
        }}
      >
        <AddIcon
          sx={{
            marginTop: 'auto',
            marginBottom: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            color: '#00000077',
          }}
        />
      </div>
    </div>
  );
};

const App = ({ max_quantity, quantity, setQuantity }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'right' }}>
      <PlusMinusButton
        value={quantity}
        minValue={1}
        maxValue={max_quantity}
        onChange={(newValue) => setQuantity(newValue)}
      />
    </div>
  );
};

export default App;
