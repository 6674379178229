import React, { useEffect, useState, useContext } from 'react';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Product.css'; // Import custom styles
import CarouselContainer from '../CarouselContainer';
import { FavoriteBorder } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomButton from '../CustomButton/CustomButton';
import PlusMinusButton from '../PlusMinusButton';
import CustomPaging from '../CustomPaging/CustomPaging';
import SectionHeader from '../SectionHeader/SectionHeader';
import ProductCard from '../ProductCard';
import Slider from '../Slider/Slider';
import { isMobile } from 'react-device-detect';
import { isTablet } from '../../deviceUtils';
import { Helmet } from 'react-helmet';
import { RWebShare } from 'react-web-share';
import ShareIcon from '@mui/icons-material/Share';
import { useParams } from 'react-router-dom';
import PropertySelector from '../PropertySelector';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import MyContext from '../../contexts/MyContext';
import Loading from '../Loading/Loading';

import {
  getProduct,
  getProductsByCategory,
  addToWishList,
  getWishList,
  addToCartCustomer,
  getCart,
} from '../../APIS';

const Product = () => {
  const [product, setProduct] = useState({
    product: {},
    images: [],
    properties: [],
  });
  const {
    productsInCart,
    setProductsInCart,
    productsInWishlist,
    setProductsInWishlist,
  } = useContext(MyContext);

  const [similerProducts, setSimilerProducts] = useState([]);
  const [inWishlist, setInwishlist] = useState(false);
  const [images, setImages] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const queryParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [loading, setLoading] = useState(false);

  let { productId } = useParams();

  const fetchProduct = async () => {
    try {
      setLoading(true);
      const response = await getProduct(productId);
      setLoading(false);

      if (response.status === 'success' && response.data.product) {
        setProduct(response.data);
        localStorage.setItem(
          'breadCrump',
          JSON.stringify([
            { label: 'الرئيسية', path: '/' },
            {
              label: response.data.product.categoryName,
              path:
                '/search?type=category&categoryId=' +
                response.data.product.categoryId,
            },
            {
              label: response.data.product.name,
              path: '/product/' + response.data.product.id,
            },
          ])
        );
        window.dispatchEvent(new Event('storage'));
        fetchProductByCategory(response.data);
        var imgs = [];
        imgs.push(response.data.product.image);
        response.data.images.forEach((img) => {
          imgs.push(img.url);
        });
        setImages(imgs);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleAddtoCartButton = async () => {
    const object = {
      cartId: localStorage.getItem('cartId'),
      productId,
      quantity,
      selectedProperties,
    };
    try {
      const response = await addToCartCustomer(object);
      if (response.status === 'success') {
        toast.success(response.msg);
        localStorage.setItem('cartId', response.newCart);
        setfetchCartSizeBages();
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      throw error;
    }
  };

  const setfetchCartSizeBages = async () => {
    try {
      const response = await getCart(localStorage.getItem('cartId'));

      if (response.status === 'success') {
        setProductsInCart(response.data.length);
      }
    } catch (error) {
      throw error;
    }
  };

  const getWishList2 = async () => {
    try {
      const response = await getWishList();
      const filteredArray = response.result.filter(
        (item) => item.id == productId
      );

      if (filteredArray.length > 0) {
        setInwishlist(true);
      } else {
        setInwishlist(false);
      }
      setProductsInWishlist(response.result.length);
    } catch (error) {
      throw error;
    }
  };

  const handleAddToWishListButton = async () => {
    try {
      const response = await addToWishList(productId);

      if (response.status === 'success') {
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
      }
      if (localStorage.getItem('token')) getWishList2();
    } catch (error) {
      throw error;
    }
  };

  const fetchProductByCategory = async (product) => {
    try {
      const response = await getProductsByCategory(product.product.categoryId);

      if (response.status == 'success') {
        setSimilerProducts(response.data.slice(0, 12));
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProduct();

    if (localStorage.getItem('token')) getWishList2();
  }, [productId]);

  return (
    <>
      <Helmet>
        <title>{product.product.name || ''}</title>
        <meta name='title' content={product.product.name || ''} />
        <meta name='description' content={product.product.description || ''} />

        <meta property='og:type' content='website' />
        <meta property='og:url' content={window.location} />
        <meta property='og:title' content={product.product.name || ''} />
        <meta
          property='og:description'
          content={product.product.description || ''}
        />
        <meta property='og:image' content={images[0]} />

        <meta property='twitter:card' content={product.product.name || ''} />
        <meta property='twitter:url' content={window.location} />
        <meta property='twitter:title' content={product.product.name || ''} />
        <meta
          property='twitter:description'
          content={product.product.description || ''}
        />
        <meta property='twitter:image' content={images[0]} />
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <div className='slider-container'>
              {isMobile ? (
                <CarouselContainer images={images} aspectRatio='1/1' />
              ) : (
                <Slider images={images} />
              )}
            </div>
            <div className='details-container'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                  backgroundColor: '#f8f8f8',
                  margin: '5px',
                }}
              >
                <p
                  style={{
                    margin: '5px',
                    textDecoration: 'rtl',
                    fontWeight: 'bold',
                  }}
                >
                  تم مشاهدة هذا المنتج {product.product.views} مرة
                </p>

                {/* <VisibilityIcon /> */}
                <img src='https://themes.pixelstrap.com/multikart/assets/images/person.gif' />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  margin: '5px',
                }}
              >
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: 24,
                    textAlign: 'right',
                  }}
                >
                  {product.product.name}
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  margin: '5px',
                }}
              >
                <p style={{ fontWeight: 'bold', fontSize: 18 }}>
                  {product.product.categoryName}{' '}
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  margin: '5px',
                  flexDirection: 'row-reverse',
                }}
              >
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {product.product.price}₪
                </p>

                {product.product.deletedprice != 0 && (
                  <div
                    style={{ display: 'flex', flexDirection: 'row-reverse' }}
                  >
                    <del
                      style={{
                        marginTop: 'auto',
                        marginRight: '10px',
                        color: '#aaa',
                      }}
                    >
                      {product.product.deletedprice}₪
                    </del>

                    <p
                      style={{
                        fontWeight: 'bold',
                        marginTop: 'auto',
                        marginRight: '10px',
                        color: '#cc4444',
                      }}
                    >
                      {Math.round(
                        ((product.product.deletedprice -
                          product.product.price) /
                          product.product.deletedprice) *
                          100
                      )}
                      %
                    </p>
                  </div>
                )}
              </div>

              {product.properties.length > 0 && (
                <div>
                  <div>
                    <div
                      style={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#ccc',
                        margin: '5px',
                      }}
                    ></div>
                    <p style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      اختر تفاصيل المنتج
                    </p>
                  </div>
                  <PropertySelector
                    properties={product.properties}
                    setSelectedProperties={setSelectedProperties}
                    selectedProperties={selectedProperties}
                  />
                </div>
              )}
              <div style={{ flexDirection: 'column' }}>
                <p style={{ textAlign: 'right', fontWeight: 'bold' }}>الكمية</p>
                <div style={{}}>
                  {/* */}
                  <div style={{ textAlign: 'right' }}>
                    <PlusMinusButton
                      max_quantity={product.product.quantity}
                      quantity={quantity}
                      setQuantity={setQuantity}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  margin: '5px',
                }}
              >
                <div
                  style={{
                    margin: '5px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    cursor: 'pointer',
                  }}
                  onClick={async () => {
                    await navigator.share({
                      title: product.product.name,
                      text:
                        'قم بمشاهدة هذا المنتج الرائع - ' +
                        product.product.name,
                      url: window.location.href,
                    });
                  }}
                >
                  <ShareIcon
                    style={{
                      color: '#fcc318',
                      fontSize: 30,
                    }}
                  />
                </div>

                {localStorage.getItem('token') && (
                  <div
                    style={{ margin: '5px' }}
                    onClick={() => {
                      handleAddToWishListButton();
                    }}
                  >
                    <CustomButton
                      component={<FavoriteBorder />}
                      inWishList={inWishlist}
                    />
                  </div>
                )}

                <div style={{ margin: '5px' }}>
                  <CustomButton
                    onClick={() => {
                      handleAddtoCartButton();
                    }}
                    text='اضف الى سلة المشتريات'
                  />
                </div>
              </div>

              {product.product.description != '' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'right',
                    margin: '5px',
                    textAlign: 'right',
                  }}
                >
                  {' '}
                  <p style={{ fontWeight: 'bold', fontSize: 24 }}>
                    تفاصيل المنتج
                  </p>
                  <p>{product.product.description}</p>
                </div>
              )}
            </div>
          </div>
          <div>
            <SectionHeader title={'منتجات مشابهة'} />
            <div
              style={{
                direction: 'rtl',

                padding: 10,
                marginTop: 10,
                borderRadius: 10,
                borderColor: '#00000011',
                display: 'grid',
                gridTemplateColumns: isMobile
                  ? 'repeat(2, 1fr)'
                  : 'repeat(6, 1fr)',
                gap: 10,
              }}
            >
              {similerProducts.map((product, index) => (
                <ProductCard product={product} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Product;
