import React, { useState, useEffect } from 'react';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import Header from './Header/Header';
import SectionHeader from './SectionHeader/SectionHeader';
import SwiperComponent from './SwiperComponent';
import { isMobile } from 'react-device-detect';
import { isTablet } from '../deviceUtils';

import BottomNavigationTabs from './BottomNavigation';
import ProductCard from './ProductCard';
import CarouselContainer from './CarouselContainer';
import CategoryCard from './CategoryCard';
import CustomButton from './CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import Carousel from './Carousel/Carousel';
import Loading from './Loading/Loading';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import {
  getCategories,
  getMostViewedProducts,
  getRandomProducts,
  getProductsListings,
  getBanners,
} from '../APIS';

const Main = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [mostViewedProducts, setMostViewedProducts] = useState([]);
  const [mostSelledProducts, setMostSelledProducts] = useState([]);
  const [distinctiveProduct, setDistinctiveProduct] = useState([]);
  const [productListings, setProductListings] = useState([]);
  const [primaryBanners, setPrimaryBanners] = useState([]);
  const [secondaryBanners, setSecondaryBanners] = useState([]);
  const [bannerIndex, setBannerIndex] = useState(0);
  const [shouldShowCarousel, setShouldShowCarousel] = useState(false);
  const navigate = useNavigate();

  const getRandomBoolean = () => Math.random() < 0.5;

  const updateBannerIndex = () => {
    setShouldShowCarousel(getRandomBoolean());
    setBannerIndex((prevIndex) => (prevIndex + 1) % secondaryBanners.length);
  };

  useEffect(() => {
    if (secondaryBanners.length > 0) {
      updateBannerIndex();
    }
  }, [productListings]);

  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      if (response.status === 'success') {
        setLoading(true);
        setCategories(response.data);
        setLoading(false);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchMostViewedProducts = async () => {
    try {
      setLoading(true);
      const response = await getMostViewedProducts();
      setLoading(false);
      if (response.status === 'success') {
        setMostViewedProducts(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchDistinctiveProducts = async () => {
    try {
      const counter = 4;
      setLoading(true);
      const response = await getRandomProducts(counter);
      setLoading(false);
      if (response.status === 'success') {
        setDistinctiveProduct(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchMostSelledProducts = async () => {
    try {
      const counter = 4;
      setLoading(true);
      const response = await getRandomProducts(counter);
      setLoading(false);
      if (response.status === 'success') {
        setMostSelledProducts(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchProductListings = async () => {
    try {
      setLoading(true);
      const response = await getProductsListings();
      setLoading(false);
      if (response.status === 'success') {
        setProductListings(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchBanners = async () => {
    try {
      setLoading(true);
      const response = await getBanners();
      setLoading(false);

      if (response.status === 'success') {
        const img_primary = [];
        const img_secondary = [];
        response.data.forEach((image) => {
          if (image.position === 'رئيسي') {
            img_primary.push(image.url);
          } else {
            img_secondary.push(image.url);
          }
        });
        setPrimaryBanners(img_primary);
        setSecondaryBanners(img_secondary);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchMostViewedProducts();
    fetchMostSelledProducts();
    fetchDistinctiveProducts();
    fetchProductListings();
    fetchBanners();
    localStorage.setItem(
      'breadCrump',
      JSON.stringify([{ label: 'الرئيسية', path: '/' }])
    );
    window.dispatchEvent(new Event('storage'));
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div
            style={{
              marginTop: '10px',
              borderRadius: '10px',
              overflow: 'hidden',
              width: '100%',
              aspectRatio: 16 / 4,
            }}
          >
            <CarouselContainer images={primaryBanners} aspectRatio={'16 / 4'} />
          </div>
          <SwiperComponent categories={categories} />
          <div
            style={{
              direction: 'rtl',
              padding: 10,
              marginTop: 10,
              borderRadius: 10,
              borderColor: '#00000011',
              display: 'grid',
              gridTemplateColumns: isMobile
                ? 'repeat(1, 1fr)'
                : 'repeat(3, 1fr)',
              gap: 10,
            }}
          >
            <div
              style={{
                border: 'solid 1px #000',
                padding: 5,
                marginTop: 10,
                borderRadius: 10,
                backgroundColor: '#fff',
                borderColor: '#00000011',
              }}
            >
              <p
                style={{
                  textAlign: 'right',
                  fontWeight: 'bold',
                  fontSize: 18,
                  marginRight: '10px',
                  marginBottom: '-20px',
                }}
              >
                الاكثر مبيعا
              </p>
              <div
                style={{
                  direction: 'rtl',
                  padding: 10,
                  marginTop: 10,
                  borderRadius: 10,
                  borderColor: '#00000011',
                  display: 'grid',
                  gridTemplateColumns: isMobile
                    ? 'repeat(2, 1fr)'
                    : 'repeat(2, 1fr)',
                  gap: 10,
                }}
              >
                {mostSelledProducts.map((product, index) => (
                  <ProductCard key={index} product={product} />
                ))}
              </div>
            </div>
            <div
              style={{
                border: 'solid 1px #000',
                padding: 5,
                marginTop: 10,
                borderRadius: 10,
                backgroundColor: '#fff',
                borderColor: '#00000011',
              }}
            >
              <p
                style={{
                  textAlign: 'right',
                  fontWeight: 'bold',
                  fontSize: 18,
                  marginRight: '10px',
                  marginBottom: '-20px',
                }}
              >
                الاكثر مشاهدة
              </p>
              <div
                style={{
                  direction: 'rtl',
                  padding: 10,
                  marginTop: 10,
                  borderRadius: 10,
                  borderColor: '#00000011',
                  display: 'grid',
                  gridTemplateColumns: isMobile
                    ? 'repeat(2, 1fr)'
                    : 'repeat(2, 1fr)',
                  gap: 10,
                }}
              >
                {mostViewedProducts.map((product, index) => (
                  <ProductCard key={index} product={product} />
                ))}
              </div>
            </div>

            <div
              style={{
                border: 'solid 1px #000',
                padding: 5,
                marginTop: 10,
                borderRadius: 10,
                backgroundColor: '#fff',
                borderColor: '#00000011',
              }}
            >
              <p
                style={{
                  textAlign: 'right',
                  fontWeight: 'bold',
                  fontSize: 18,
                  marginRight: '10px',
                  marginBottom: '-20px',
                }}
              >
                المنتجات المميزة
              </p>
              <div
                style={{
                  direction: 'rtl',
                  padding: 10,
                  marginTop: 10,
                  borderRadius: 10,
                  borderColor: '#00000011',
                  display: 'grid',
                  gridTemplateColumns: isMobile
                    ? 'repeat(2, 1fr)'
                    : 'repeat(2, 1fr)',
                  gap: 10,
                }}
              >
                {distinctiveProduct.map((product, index) => (
                  <ProductCard key={index} product={product} />
                ))}
              </div>
            </div>
          </div>

          {productListings.map((category, index) => (
            <div key={index}>
              {category.products.length > 0 && (
                <>
                  <SectionHeader title={category.categoryName} />
                  <div
                    style={{
                      direction: 'rtl',
                      padding: 10,
                      marginTop: 10,
                      borderRadius: 10,
                      borderColor: '#00000011',
                      display: 'grid',
                      gridTemplateColumns: isMobile
                        ? 'repeat(2, 1fr)'
                        : 'repeat(6, 1fr)',
                      gap: 10,
                    }}
                  >
                    {category.products.map((product, index) => (
                      <ProductCard key={index} product={product} />
                    ))}
                  </div>
                  <CustomButton
                    text={'مشاهدة المزيد >>'}
                    onClick={() => {
                      navigate(
                        '/search?type=category&categoryId=' +
                          category.categoryId
                      );
                    }}
                  />
                  {index == 0 && (
                    <div
                      style={{
                        borderRadius: 10,
                        overflow: 'hidden',
                        marginTop: 10,
                      }}
                    >
                      <CarouselContainer
                        images={secondaryBanners}
                        aspectRatio={'16 / 3'}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          ))}

          {isMobile && <BottomNavigationTabs />}
        </>
      )}
    </>
  );
};

export default Main;
