import React from 'react';
import Logo from '../Logo'; // Adjust the import path as needed
import './Loading.css';

const Loading = () => {
  return (
    <div style={styles.overlay}>
      <div style={styles.spinner}>
        <Logo style={styles.logo} />
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.7)', // Adjust opacity here
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
  },
  spinner: {
    animation: 'spin 2s linear infinite',
  },
  logo: {
    width: '90px', // You can adjust this or use the styles from Logo component
    height: '90px', // You can adjust this or use the styles from Logo component
    animation: 'rotate 2s linear infinite', // Apply rotation animation
  },
};

export default Loading;
