import React, { useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Skeleton from '@mui/material/Skeleton';

const CarouselContainer = ({ images, aspectRatio }) => {
  return (
    <>
      {images.length > 0 ? (
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          axis={'horizontal'}
          showArrows={true}
        >
          {images.map((src, index) => (
            <img
              key={index}
              style={{
                width: '100%',
                aspectRatio,
                objectFit: 'cover',
              }}
              src={src}
              alt={`Image ${index + 1}`}
            />
          ))}
        </Carousel>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Skeleton
            sx={{ width: '100%', aspectRatio }}
            variant='rectangular'
            // width={'80%'}
            height={'auto'}
          />
        </div>
      )}
    </>
  );
};

export default CarouselContainer;
