import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';

const ProductCardForOrderDetails = ({ product }) => {
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const cardStyle = {
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.7fr 0.2fr 0.2fr', // Adjust the number of columns as needed
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '8px',
    marginTop: '10px',
    direction: 'rtl',
    gridAutoRows: 'auto', // Set each row's height to adjust automatically based on content
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  const imageStyle = {
    // backgroundColor: 'yellow',
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    textAlign: 'right',
  };

  const titleStyle = {
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'right', // Right-align the title
    cursor: 'pointer',
    direction: 'ltr',
  };

  const textStyle = {
    margin: '5px',
    textAlign: 'right', // Right-align text content
    fontSize: '12px',
    marginTop: -10,
    marginRight: 0,
    color: '#ccc',
    fontWeight: 'bold',
  };

  const buttonStyle = {};

  const buttonContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '4px',
  };

  const button = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: 10,
    height: 10,
    padding: '8px',
    fontSize: '1.2rem',
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: '#f0b216',
  };

  const countText = {
    fontSize: '1rem',
    margin: '4px',
  };

  const incrementCount = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const decrementCount = () => {
    if (count > 0) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  return (
    <div>
      <div key={product.id} style={cardStyle}>
        <div style={imageStyle}>
          <img
            onClick={() => {
              navigate(`/product/${product.productId}`);
            }}
            style={{
              objectFit: 'cover',
              width: '100%',
              aspectRatio: 1 / 1,
              maxWidth: '100px',
              cursor: 'pointer',
            }}
            src={product.image}
          />
        </div>

        <div style={contentStyle}>
          <h5
            style={titleStyle}
            onClick={() => {
              navigate(`/product/${product.productId}`);
            }}
          >
            {product.quantity} X {product.name}
          </h5>
          <p style={textStyle}>{product.category}</p>
          <p
            style={{
              marginTop: 0,
              fontSize: '12px',
              marginRight: 0,
              color: '#cc0000',
              fontWeight: 'bold',
            }}
          >
            ₪{product.price.toFixed(2)}
          </p>
        </div>

        <div>
          <p> خصائص المنتج</p>
          {product.options.map((option) => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p style={{ margin: '5px' }}>{option.label}</p>
                <p style={{ margin: '5px' }}>{option.value}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductCardForOrderDetails;
