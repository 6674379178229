import React, { useEffect, useState, useContext } from 'react';
import './App.css';
import Main from './components/Main';
import Product from './components/Product/Product';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import { isMobile } from 'react-device-detect';
import FrontTest from './components/FrontTest';
import Cart from './components/Cart';
import WishList from './components/WishList';
import FloatingProduct from './components/FloatingProduct/FloatingProduct';
import MyProvider from './providers/MyProvider';
import BottomNavigationTabs from './components/BottomNavigation';
import Search from './components/Search';
import LastOrders from './components/LastOrders';
import Profile from './components/Profile';
import OrderDetails from './components/OrderDetails';
import { ToastContainer } from 'react-toastify';
import MyContext from './contexts/MyContext';
import { Helmet } from 'react-helmet';

import { getWishList, getCart } from './APIS';
import { useLocation } from 'react-router-dom';
import io from 'socket.io-client';
const socket = io('https://api.hamarshehstore.com'); // Change to your server's URL if necessary

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#fcc318',
      },
      secondary: {
        main: '#f44336',
      },
    },
  });
  const location = useLocation();
  const {
    productsInCart,
    setProductsInCart,
    productsInWishlist,
    setProductsInWishlist,
  } = useContext(MyContext);

  const fetchWishList = async () => {
    try {
      const response = await getWishList();
      setProductsInWishlist(response.result.length);
    } catch (error) {
      throw error;
    }
  };

  const setfetchCartSizeBages = async () => {
    try {
      const response = await getCart(localStorage.getItem('cartId'));

      if (response.status === 'success') {
        setProductsInCart(response.data.length);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      fetchWishList();
    }

    setfetchCartSizeBages();
  }, [location]);
  const isTablet = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    // Define tablet dimensions (you can adjust these as needed)
    const isTabletSize = width >= 768 && width <= 1024 && height <= 1366;
    return isTabletSize;
  };

  return (
    <>
      <Helmet>
        <title>الحمارشة للادوات المنزلية</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <div
          className='App'
          style={
            {
              // backgroundColor: '#F7F7FA',
            }
          }
        >
          <div style={{ direction: 'rtl', backgroundColor: '#fff' }}>
            <Header />
          </div>
          <div
            style={{
              width: isMobile || isTablet() ? '97%' : '70%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Routes>
              <Route path='/' element={<Main />}></Route>
              <Route path='/product/:productId' element={<Product />}></Route>
              <Route path='/cart' element={<Cart />}></Route>
              <Route path='/wishList' element={<WishList />}></Route>
              <Route path='/search' element={<Search />}></Route>
              <Route path='/lastorders' element={<LastOrders />}></Route>
              <Route
                path='/lastorders/:orderId'
                element={<OrderDetails />}
              ></Route>
              <Route path='/profile' element={<Profile />}></Route>
            </Routes>
          </div>

          {/* <Product /> */}
          {isMobile && <BottomNavigationTabs />}
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
