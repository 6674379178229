const url = 'https://api.hamarshehstore.com/api';

export const notfound = url + '/uploads/notfound.png';
export const signup = async ({ name, mobile, city, address, password }) => {
  try {
    const response = await fetch(`${url}/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, mobile, city, address, password }),
    });

    return response.json();
  } catch (error) {
    throw error;
  }
};

export const customerLogin = async ({ mobile, password }) => {
  try {
    const response = await fetch(`${url}/customerLogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mobile, password }),
    });
    localStorage.removeItem('cartId');

    return response.json();
  } catch (error) {
    throw error;
  }
};

export const changePasswordCustomer = async (oldPassword, newPassword) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${url}/changePasswordCustomer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ oldPassword, newPassword }),
    });

    const responseData = await response.json();

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    return responseData;
  } catch (error) {
    throw error;
  }
};

export const getCategories = async () => {
  try {
    const response = await fetch(`${url}/getCategories`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const getProductsByCategory = async (categoryId) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(
      `${url}/getProductsByCategory?categoryId=${categoryId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const searchProducts = async (que) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${url}/searchProducts?que=${que}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMostViewedProducts = async () => {
  try {
    const response = await fetch(`${url}/getMostViewedProducts`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const getRandomProducts = async (counter) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${url}/getRandomProducts?count=${counter}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getProductsListings = async () => {
  try {
    const response = await fetch(`${url}/getProductsListings`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const getProduct = async (id) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${url}/getProduct?id=${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getBanners = async () => {
  try {
    const response = await fetch(`${url}/getBanners`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const getCities = async () => {
  try {
    const response = await fetch(`${url}/getCities`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserInfo = async () => {
  try {
    const response = await fetch(`${url}/getUserInfo`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const editUserInfo = async (object) => {
  const token = localStorage.getItem('token');

  try {
    const response = await fetch(`${url}/editUserInfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(object),
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit user: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const addToWishList = async (productId) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(
      `${url}/addToWishList?productId=${productId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const getWishList = async () => {
  try {
    const response = await fetch(`${url}/getWishList`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const addToCartCustomer = async (object) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${url}/addToCartCustomer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(object),
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const getCart = async (id) => {
  try {
    const response = await fetch(`${url}/getCartCustomer?id=${id || ''}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkCoupon = async (code) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${url}/checkCoupon?code=${code}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const editCartQuantity = async (object) => {
  const token = localStorage.getItem('token');

  try {
    const response = await fetch(
      `${url}/editCartQuantity?quantity=${object.quantity}&cartId=${object.cartId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit user: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const createOrderCustomer = async (order) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${url}/createOrderCustomer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(order),
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add payment: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const getAllOrdersDetailsUser = async () => {
  try {
    const response = await fetch(`${url}/getAllOrdersDetailsUser`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const getOrderDetailsUser = async (orderId) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(
      `${url}/getOrderDetailsUser?orderId=${orderId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all products in cart after delete
export const deleteFromCart = async (id) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${url}/deleteFromCart?id=${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkOTP = async (mobile, otp) => {
  const token = localStorage.getItem('token');

  try {
    const response = await fetch(
      `${url}/checkOTP?mobile=${mobile}&otp=${otp}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    throw error;
  }
};

// Function to change password
export const forgotPassword = async (mobile) => {
  try {
    const response = await fetch(`${url}/forgotPassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mobile,
      }),
    });

    // if (!response.ok) {
    //   throw new Error('Failed to change password');
    // }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};

export const newPasswordCustomer = async (password, token) => {
  try {
    const response = await fetch(`${url}/newPasswordCustomer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        password,
      }),
    });

    // if (!response.ok) {
    //   throw new Error('Failed to change password');
    // }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};
